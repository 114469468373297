import React from "react";

const listPelaksanaan = {
    "list-pelaksanaan": 
    [
        {
            kategori: 0,
            text: [
                {nomor: "1", isi: "Surat Edaran Direktur Pendidikan Tinggi No. 0387/ETM.02.00/2024 tentang Panduan Pengenalan Kehidupan Kampus bagi Mahasiswa Baru (PKKMB) tahun 2024."},
                {nomor: "2", isi: "Mahasiswa terdorong untuk lebih percaya diri dalam mengekspresikan dirinya untuk bertindak, berinovasi, dan berkarya."},
                {nomor: "3", isi: "Mahasiswa termotivasi untuk berpikir kritis yang didampingi oleh kebijaksanaan dalam bertindak di berbagai situasi."},
                {nomor: "4", isi: "Mahasiswa menerima pengembangan karakter agar memiliki sikap sebagai intelektuan, antikekerasan seksual, antiperundungan, antinarkoba, antikorupsi, dan kampus sehat."},
                {nomor: "5", isi: "Mahasiswa mendapatkan pemahaman yang mendalam tentang kehidupan berbangsa, bernegara, dan pembinaan kesadaran bela negara."},
                {nomor: "6", isi: `Nilai 5C Universitas Multimedia Nusantara. <br /><br />
                    <b><i>Caring</i></b>:<br/> Mahasiswa memiliki sifat untuk peduli terhadap diri sendiri, sesama, hingga lingkungan sekitarnya. <br /><hr/>
                    <b><i>Credible</i></b>:<br/> Mahasiswa memiliki integritas yang tinggi, kesadaran berorganisasi, dan dapat diandalkan. <br /><hr />
                    <b><i>Competent</i></b>:<br/> Mahasiswa memiliki penguasaan terhadap bidang yang dipelajari secara profesional dan mampu bekerja cerdas untuk hasil yang terbaik. <br /><hr />
                    <b><i>Competitive</i></b>:<br/> Mahasiswa memiliki sikap untuk berkompetisi mencapai prestasi yang terbaik. <br /><hr />
                    <b><i>Customer Delight</i></b>:<br/> Mahasiswa memiliki sifat untuk memberikan pelayanan terbaik bagi sesamanya, bahkan bisa lebih dari yang diharapkan.<br /><hr />`
                },
            ]
        },
        {
            kategori: 1,
            text: [
                {nomor: "1", isi: "Sebagai proses seremonial mahasiswa baru untuk menjadi bagian dari Keluarga Besar Mahasiswa Universitas Multimedia Nusantara (KBM UMN)."},
                {nomor: "2", isi: "Sebagai syarat kelulusan mahasiswa Universitas Multimedia Nusantara."},
                {nomor: "3", isi: "Sebagai implementasi dari Keputusan Direktur Jenderal Pendidikan Tinggi KEMENDIKBUD RI No. 0387/E/TM.02.00/2024 tentang Panduan Pengenalan Kehidupan Kampus bagi Mahasiswa Baru (PKKMB) tahun 2024."},
            ]
        },
        {
            kategori: 2,
            text: [
                {nomor: "1", isi: "Memberikan pemahaman mendalam terhadap lingkungan Universitas Multimedia Nusantara kepada mahasiswa baru."},
                {nomor: "2", isi: "Mengajak mahasiswa baru untuk mengembangkan keterampilan, pengetahuan, serta dukungan sosial yang diperlukan untuk sukses dalam perjalanan akademis dan kehidupan kampus."},
                {nomor: "3", isi: "Merangsang interaksi positif antar mahasiswa sehingga timbul kenyamanan dari mahasiswa dalam aktif mengajukan pendapatnya."},
                {nomor: "4", isi: "Menciptakan lingkungan inklusif yang memberikan apresiasi atau dukungan yang dibutuhkan mahasiswa dalam berproses di dunia perkuliahan."},
            ]
        }
    ]
}

export default listPelaksanaan;
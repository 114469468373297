const scheduleData = {
    "BALWANA": [
        {
            room: "Lotus Biru s’lalu bijaksana, ",
            date: "Selamat kamu terpilih menjadi anggota Balwana! ",
            participants: [
                { name: "Livianny Allycia", nim: "00000082557" },
                { name: "Jennifer Arcelia", nim: "00000092674" },
                { name: "Shareefa Aisyah", nim: "00000070084" },
                { name: "Derren Pranata", nim: "00000090085" },
                { name: "Jethro Jotham", nim: "00000069178" },
                { name: "Brychitta Natalie", nim: "00000093033" },
                { name: "Joselino Frederick", nim: "00000082565" },
                { name: "Louissa Sofia", nim: "00000092122" },
                { name: "Fransisca Lee Christiany", nim: "00000106737"},
                { name: "Vallencia Natalie", nim: "00000069660"},
            ]
        },
    ],
    "CAKSANA": [
        {
            room: "Lotus Biru s’lalu sigap membuat rencana, ",
            date: "Selamat kamu terpilih menjadi anggota Caksana! ",
            participants: [
                {name: "Cherish Dominick", nim: "00000096231"},
                {name: "Rachel Natallie", nim: "00000054096"},
                {name: "Ferdinand Julius", nim: "00000056186"},
                {name: "Stefani Margaretha", nim: "00000053487"},
                {name: "Paramitha Irgina", nim: "00000108235"},
                {name: "Silvia Andriyani", nim: "00000063921"},
                {name: "Beatrice Ellena", nim: "00000082564"},
                {name: "Lawrencia", nim: "00000076947"},
                {name: "Gabriela Salim", nim: "00000068051"},
            ]
        },
    ],  
    "LAVANYA": [
        {
            room: "Lotus Biru pintar akalnya, ",
            date: "Selamat kamu terpilih menjadi calon anggota Lavanya! ",
            participants: [
                {name: "David Wang", nim: "00000089554"},
                {name: "Ruus, Auliana", nim: "00000089787"},
                {name: "Rara Anggreni", nim: "00000068958"},
                {name: "Qonita Azalia", nim: "00000093589"},
                {name: "Stacyana Susanto", nim: "00000077096"},
            ]
        },
    ],
    "BIMASENA": [
        {
            room: "Lotus Biru gemar memberi makna,",
            date: "Selamat kamu terpilih menjadi calon Bimasena!",
            participants: [
                {name: "Olivier Rakha", nim: "00000069523"},
                {name: "Clementia Chessa", nim: "00000088637"},
                {name: "Japhia Jehosheba", nim: "00000096858"},
                {name: "Samuel Matthew", nim: "00000069954"},
                {name: "Phillipe Edwardo", nim: "00000069884"},
                {name: "Thomas Daviniel", nim: "00000069939"},
                {name: "Petrus Bonaventura", nim: "00000073443"},
                {name: "Eriklimawan", nim: "00000097544"},
                {name: "Farrel", nim: "00000099012"},
                {name: "Marchel Halim", nim: "00000092171"},
                {name: "Theola Alvarizka", nim: "00000103328"},
                {name: "Keane Subrata", nim: "00000091463"},
                {name: "William Purba", nim: "00000066368"},
                {name: "Benediktus Deamaren", nim: "00000096061"},
                {name: "Lyvia Rose", nim: "00000093499"},
                {name: "Ferry Halim", nim: "00000090087"},
                {name: "Darryl Cornelius", nim: "00000098874"},
                {name: "Fransiskus Bagas", nim: "00000089286"},
                {name: "Lia Priyatiningrum", nim: "00000110571"},
                {name: "Nadine Ayu", nim: "00000099620"},
                {name: "Ricardo Cipta", nim: "00000098947"},
                {name: "Gregorius Gigih", nim: "00000097022"},
                {name: "Jeanette Aprilia", nim: "00000092425"},
                {name: "Muhammad Rizky", nim: "00000092425"},
                {name: "Surya Novensky", nim: "00000073204"},
                {name: "Dindaniz Sabrina", nim: "00000108624"},
                {name: "Nathanael Clarence", nim: "00000099766"},
                {name: "Fransiskus Devin", nim: "00000082030"},
                {name: "Christopher Joshua", nim: "00000077020"},
                {name: "Hamilton Valentino", nim: "00000084272"},
                {name: "Richard Giansanto", nim: "00000076941"},
                {name: "Andriana Cipta", nim: "00000068916"},
                {name: "Angelica Victoria", nim: "00000077048"},
                {name: "Daffa Surya", nim: "00000109981"},
                {name: "Maryellen Eunike", nim: "00000082217"},
                {name: "Livinia Cathleen", nim: "00000068922"},
                {name: "Naila Asylah", nim: "00000082479"},
                {name: "Felisiana Kristiani", nim: "00000108267"},
                {name: "Efránio Babo", nim: "00000067720"},
                {name: "Zerounia Meisya", nim: "00000095646"},
                {name: "Leory Lee", nim: "00000082013"},
                {name: "Belicia Vevila", nim: "00000111374"},
                {name: "Made Arya", nim: "00000112950"},
                {name: "Grace Meliza", nim: "00000076793"},
                {name: "Alexa Putri", nim: "00000081800"},
                {name: "Jacyntha Octavania", nim: "00000091131"},
                {name: "Meldean Jonathan", nim: "00000079469"},
                {name: "Muhammad Sulthan", nim: "00000108768"},
                {name: "Kheisha Twyla", nim: "00000078386"},
                {name: "Mohamad Vidie", nim: "00000099848"},
                {name: "Raffael Valentino", nim: "00000091170"},
                {name: "Ryane Kalyana", nim: "00000112449"},
                {name: "Glend Yesaya", nim: "00000105712"},
                {name: "Kayfa Razqia", nim: "00000104933"},
                {name: "Adriano Bimo", nim: "00000083472"},
                {name: "Cecilia Margaretha", nim: "00000071675"},
                {name: "Geovandy O'", nim: "00000099070"},
                {name: "Beneisha Bat", nim: "00000092821"},
                {name: "Radiant Putra", nim: "00000077191"},
                {name: "Wilson Hermawan", nim: "00000077021"},
                {name: "Geofrey Mercyo", nim: "00000100862"},
                {name: "Aryanda Rahman", nim: "00000092345"},
            ]
        },
    ],
    "BASKARA": [
        {
            room: "Lotus Biru bersinar bak lentera,",
            date: "Selamat kamu terpilih menjadi anggota Baskara!",
            participants: [
                {name: "Andrew Su", nim: "00000066301"},
                {name: "Rava Parisya", nim: "00000100598"},
                {name: "Yohanes Govinda", nim: "00000070005"},
                {name: "Valentino Kriswahyudi", nim: "00000077571"},
                {name: "Raden Muhammad", nim: "00000082838"},
                {name: "I Made", nim: "00000082818"},
                {name: "Margareth Ritonga", nim: "00000061478"},
                {name: "Sakti Yudha", nim: "00000078622"},
                {name: "Allison Gressia", nim: "00000067848"},
                {name: "Maria Oxa", nim: "00000082451"},
                {name: "Kristian Delon", nim: "00000088703"},
                {name: "Steven Hans", nim: "00000090248"},
                {name: "Diana Angyulia", nim: "00000063336"},
                {name: "Qhedyzya Ricardiane", nim: "00000067725"},
                {name: "Elizabeth Larasati", nim: "00000078123"},
                {name: "Darren", nim: "00000077095"},
                {name: "Maria Cahaya", nim: "00000069169"},
                {name: "Salman Aqiil", nim: "00000056118"},
                {name: "I Putu", nim: "00000068368"}
            ]            
        },
    ],
    "GAJENDRA": [
        {
            room: "Lotus Biru menjelajahi nusantara,",
            date: "Selamat kamu terpilih menjadi anggota Gajendra!",
            participants: [
                {name: "Alfonsus Aryaveda", nim: "00000082562"},
                {name: "Audy Hendry", nim: "00000053545"},
                {name: "Exan Putra", nim: "00000098441"},
                {name: "Fransiskus Asisi", nim: "00000097514"},
                {name: "Juantama", nim: "00000090132"},
                {name: "Kevin Rakhmad", nim: "00000108073"},
                {name: "Koong Hiap", nim: "00000055136"},
                {name: "Luthfansya Haikal", nim: "00000073505"},
                {name: "Raihan Maulidar", nim: "00000104962"},
                {name: "Ignatius Rayden", nim: "00000101482"},
                {name: "Reynard Geovanni", nim: "00000090179"},
                {name: "Muhammad Salman", nim: "00000094142"},
                {name: "Ananda Refi", nim: "00000107621"},
                {name: "Nicholas Nelson", nim: "00000087430"},
                {name: "Muhammad Irfan", nim: "00000110639"},
            ]            
        }, 
    ],
    "SAKHA": [
        {
            room: "Lotus Biru gemar berusaha,",
            date: "Selamat kamu terpilih menjadi calon Sakha!",
            participants: [
                {name: "Yohanes Riangto", nim: "00000065501"},
                {name: "James Arpiar", nim: "00000095278"},
                {name: "Carin Indra", nim: "00000106002"},
                {name: "Evelyn Vania", nim: "00000091634"},
                {name: "Jennifer Jannice", nim: "00000109230"},
                {name: "Darlene Chryshilla", nim: "00000104630"},
                {name: "Ivana Fifinella", nim: "00000106058"},
                {name: "Maulana Rivai", nim: "00000110372"},
                {name: "Amira Maritza", nim: "00000103304"},
                {name: "Peony Yohanes", nim: "00000090376"},
                {name: "Khaira Khansa", nim: "00000092216"},
                {name: "Samantha Luvian", nim: "00000077578"},
                {name: "Velisya Angi", nim: "00000094718"},
                {name: "Francisco Anderson", nim: "00000080911"},
                {name: "Feinisia Andani", nim: "00000092406"},
                {name: "Fikri Ekandra", nim: "00000094268"},
                {name: "Kezia Elia", nim: "00000091187"},
                {name: "Richard Sanjaya", nim: "00000094727"},
                {name: "Gabriel Javito", nim: "00000095963"},
                {name: "Glen Andrian", nim: "00000092955"},
                {name: "Maximized William", nim: "00000078953"},
                {name: "Benedict William", nim: "00000108421"},
                {name: "Cindy Fidelia", nim: "00000081973"},
                {name: "Dhaniel Chang", nim: "00000098658"},
                {name: "Kelvin Sutadi", nim: "00000089625"},
                {name: "Cherine", nim: "00000067654"},
                {name: "Michael Tamba", nim: "00000090149"},
                {name: "Alroy Reynardo", nim: "00000072105"},
                {name: "Felix", nim: "00000107805"},
                {name: "Elsa Shaqia", nim: "00000111036"},
                {name: "Stanislaus Jasson", nim: "00000091088"},
                {name: "Jap Feodrian", nim: "00000083500"},
                {name: "Jessie Yenca", nim: "00000065172"},
                {name: "Shanne Nichole", nim: "00000079195"},
                {name: "Felicia Frelli", nim: "00000102227"},
                {name: "Bryan Emmanuel", nim: "00000097731"},
                {name: "Catherine Harlinata", nim: "00000085482"},
                {name: "Yar Muhammad", nim: "00000110993"},
                {name: "Daniel Geraldy", nim: "00000104154"},
                {name: "Kayla Abbiegail", nim: "00000093798"},
                {name: "Samuel Fernandtio", nim: "00000068117"},
                {name: "Metta Florensia", nim: "00000089534"},
                {name: "Anna Hanafi", nim: "00000079802"},
                {name: "Indah Safitri", nim: "00000106229"},
                {name: "Gabriel Felicia", nim: "00000065062"},
                {name: "Charmaine Astrella", nim: "00000083449"},
                {name: "Enrico Felix", nim: "00000103230"},
                {name: "Angeline Lie", nim: "00000089580"},
                {name: "Vina Centiawan", nim: "00000092332"},
                {name: "Airin Suryani", nim: "00000096026"},
                {name: "Eugenia Fidella", nim: "00000082450"},
                {name: "Felix Octaniel", nim: "00000101926"},
                {name: "Rehuel Tisya", nim: "00000091613"},
                {name: "Angeliqe Isabel", nim: "00000078290"},
                {name: "Felicia Mardjuki", nim: "00000099081"},
                {name: "Georgerius Ricardo", nim: "00000096120"},
                {name: "Tirtayani Padmasari", nim: "00000091606"},
                {name: "Melvie Ho", nim: "00000067626"},
                {name: "Valencia Fiorentina", nim: "00000095928"},
                {name: "Vito Nathayo", nim: "00000103332"},
                {name: "Sean Rizkiardy", nim: "00000107931"},
                {name: "Az Zahra Aulia", nim: "00000106379"},
                {name: "Jenice Aurelia", nim: "00000091157"},
                {name: "Felita Aileen", nim: "00000070576"},
                {name: "Graciella Nathania", nim: "00000090217"},
                {name: "Christian Yoga", nim: "00000067471"},
                {name: "Sharly Pravda", nim: "00000106793"},
                {name: "Chairin Thamrin", nim: "00000098028"},
                {name: "Fenny Caroline", nim: "00000089156"},
                {name: "Rafi Raditya", nim: "00000095315"},
                {name: "Callista Jasmine", nim: "00000110963"},
                {name: "Amanda Citra", nim: "00000092786"},
                {name: "Carmella Fitrya", nim: "00000104407"},
                {name: "Jonathan Suryajaya", nim: "00000091082"},
                {name: "Richie Giansanto", nim: "00000076847"},
                {name: "Ronald Ramlian", nim: "00000079786"},
                {name: "Angellie", nim: "00000092359"},
                {name: "Kevin Thenggano", nim: "00000100980"},
                {name: "Audrey Naoko", nim: "00000093171"},
                {name: "Gavrila Liona", nim: "00000093803"},
                {name: "Joycelyn Sharon", nim: "00000084354"},
                {name: "Yuliani", nim: "00000093306"},
                {name: "Benedictus Agsel", nim: "00000106221"},
                {name: "Michelle Fernanda", nim: "00000080229"},
                {name: "Melvin Tristan", nim: "00000074745"},
                {name: "Carolyne Kyneisha", nim: "00000076919"},
                {name: "Jeremy Dominic", nim: "00000103941"},
                {name: "Natan Adi", nim: "00000079860"},
                {name: "Brandon Sachio", nim: "00000092240"},
                {name: "Jolin Stefanie", nim: "00000093600"},
                {name: "Marvella Charissa", nim: "00000100819"},
                {name: "Eugenia Sunako", nim: "00000105184"},
                {name: "Airin Frantrishia", nim: "00000093892"},
                {name: "Abigail Vania", nim: "00000076945"},
                {name: "Wilson Roblens", nim: "00000077708"},
                {name: "Kezia Laurencia", nim: "00000094936"},
                {name: "Kezia Graciella", nim: "00000093057"},
                {name: "Kiev Immanuel", nim: "00000072670"},
                {name: "Aryabell Boston", nim: "00000104031"},
                {name: "Daniel Gouwardi", nim: "00000081820"},
                {name: "Celvin Cristyanto", nim: "00000069280" },
                {name: "Aisyah Luviola", nim: "00000098520" },
                {name: "Muhammad Iqbal", nim: "00000110573" },
                {name: "Zahwa Marliana", nim: "00000109449" },
                {name: "Abel Oktavian", nim: "00000093793" },
                {name: "Betrand Cornelius", nim: "00000082447" },
                {name: "Rafael Nicholas", nim: "00000092399" },
                {name: "Angelica Meideline", nim: "00000093193" },
                {name: "Umi Salamah", nim: "00000110843" },
                {name: "Yudith Rahmadiah", nim: "00000112859" },
                {name: "Elena Coarin", nim: "00000092274" },
                {name: "Angeline Kayla", nim: "00000099531" },
                {name: "Alexander Briant", nim: "00000099774" },
                {name: "Feri Rifki", nim: "00000114030" },
                {name: "Melda Rantika", nim: "00000107813" },
                {name: "Wizart Keane", nim: "00000080983" },
                {name: "Dylan Ervian", nim: "00000075730" },
                {name: "Christalin Barbietta", nim: "00000090339" },
                {name: "Chellensya Beatrice", nim: "00000101008" },
                {name: "Axel Leopulisa", nim: "00000101531" },
                {name: "Filbert Fausto", nim: "00000068593" },
                {name: "William Kwok", nim: "00000082480" },
                {name: "Marvell Wijaya", nim: "00000093796" },
                {name: "Patricia Huang", nim: "00000109227" },
                {name: "Rio Hawari", nim: "00000109470" },
                {name: "Alya Virgia", nim: "00000111025" },
                {name: "Jose Antonio", nim: "00000081618" },
                {name: "Maddava Arya", nim: "00000093678" },
                {name: "Cerina Kahayani", nim: "00000093822" },
                {name: "Nadine Starleen", nim: "00000092284" },
                {name: "Callista Feodora", nim: "00000101799" },
                {name: "Putri Videlia", nim: "00000109353" },
                {name: "Cassia Wulandari", nim: "00000108585" },
                {name: "Dennis Marcello", nim: "00000075159" },
                {name: "Nicky Puji", nim: "00000106285" },
                {name: "Adeline Gracia", nim: "00000099129" },
                {name: "Desmonda Felim", nim: "00000091507" },
                {name: "Chaterine Laurenthia", nim: "00000067669" },
                {name: "Nashaffa Novadhan", nim: "00000108838" },
                {name: "Bryant Sachio", nim: "00000092440" },
                {name: "Aurelia Keianne", nim: "00000095736" },
                {name: "Keira Nicole", nim: "00000089719" },
                {name: "Florence Callista", nim: "00000108171" },
                {name: "Kayla Nugroho", nim: "00000100585" },
                {name: "Lifkie Lie", nim: "00000081835" },
                {name: "Muhammad Kynan", nim: "00000088284" },
                {name: "Claditte Qisthidarari", nim: "00000089619" },
                {name: "Devina Setiawan", nim: "00000107896" },
                {name: "Chiara Andini", nim: "00000069039" },
                {name: "Varrel Noas", nim: "00000109877"},
                {name: "Michelle Angelina", nim: "00000093742"},
                {name: "Dennis Aaron", nim: "00000079897"},
                {name: "Aisyah Ayu", nim: "00000098252"},
                {name: "Naura Sita", nim: "00000099865"},
                {name: "Alya Geraldine", nim: "00000089178"},
                {name: "Livia Hadi", nim: "00000089038"},
                {name: "Putri Aurelia", nim: "00000107626"},
                {name: "Lovenia Kirana", nim: "00000069643"},
                {name: "Evelyn Valencia", nim: "00000092683"},
                {name: "Celine Grace", nim: "00000093278"},
                {name: "Fakhira Putri", nim: "00000113010"},
                {name: "Rahadina Adisty", nim: "00000106427"},
                {name: "Nicholas Kesuma", nim: "00000101382"},
                {name: "Felicia Jennifer", nim: "00000069537"},
                {name: "Vincentius Rafael", nim: "00000071271"},
                {name: "Brightly Virya", nim: "00000068227"},
                {name: "Angelina Alexander", nim: "00000079321"},
                {name: "Dicky Rusandana", nim: "00000080879"},
                {name: "Ryan Erlanda", nim: "00000093878"},
            ]            
        },
    ],
    "NAYANIKA": [
        {
            room: "Lotus Biru menjaga pusaka,",
            date: "Selamat kamu terpilih menjadi anggota Nayanika!",
            participants: [
                { name: "Kafijaya", nim: "00000061651" },
                { name: "Caleb Steve", nim: "00000056896" },
                { name: "Axel Ferdinand", nim: "00000055952" },
                { name: "Kevin Ken", nim: "00000067060" },
                { name: "Lola Naomi", nim: "00000081913" },
                { name: "Deswandy Wong", nim: "00000090103" },
                { name: "Valentino Fernando", nim: "00000069503" },
            ]
        },
    ],
    "SARKARA": [
        {
            room: "Lotus Biru disinari sang kejora,",
            date: "Selamat kamu terpilih menjadi anggota Sarkara!",
            participants: [
                {name: "Batara Hotdo", nim: "00000078626"},
                {name: "Joy Rockniel", nim: "00000069233"},
                {name: "Kevin Steven", nim: "00000083609"},
                {name: "Nayla Shakila", nim: "00000087437"},
                {name: "Shavelle Gautami", nim: "00000079887"},
                {name: "Vincentius Benedictus", nim: "00000081958"},
                {name: "Jennifer Amanda", nim: "00000093772"},
                {name: "Jessi Anabel", nim: "00000110249"},
                {name: "Josephin Narumi", nim: "00000093301"},
                {name: "Marcell Daniel", nim: "00000069473"},
                {name: "Shyerly Shaferya", nim: "00000073622"},
                {name: "Annisa Maulia", nim: "00000092386"},
                {name: "Daniel", nim: "00000065917"},
                {name: "Kathleen Aurelia", nim: "00000083319"},
                {name: "Farrel Abimanyu", nim: "00000069168"},
                {name: "Benedikta Kumala", nim: "00000105295"},
                {name: "Frissa Budiansyah", nim: "00000087470"},
                {name: "Larissa Ika", nim: "00000071500"},
                {name: "Nadia Sekar", nim: "00000075819"},
                {name: "Shu Evangeline", nim: "00000092835"},
                {name: "Syaif Cahyo", nim: "00000099340"},
                {name: "Tiffany Titania", nim: "00000087499"},
                {name: "Yasmine Aghnia", nim: "00000099403"}
            ]            
        },
    ],
};

for (const division in scheduleData) {
    scheduleData[division].forEach(session => {
        session.participants.sort((a, b) => {
            if (a.nim.match(/\d{2}:\d{2} WIB/) && b.nim.match(/\d{2}:\d{2} WIB/)) {
                return a.nim.localeCompare(b.nim);
            } else {
                return a.name.localeCompare(b.name);
            }
        });
    });
}

export default scheduleData;

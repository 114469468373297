const DaftarFaq = {
  "daftar-faq-omb": [
    {
      pertanyaan: "Apakah setiap calon mahasiswa baru wajib mengikuti OMB UMN 2024?",
      jawaban: "<b>Iya</b>. Setiap calon mahasiswa baru wajib mengikuti seluruh rangkaian kegiatan OMB UMN 2024 sebagai <b>salah satu syarat kelulusan.</b>",
      tipe: [0],
      nomor: 1,
    },
    {
      pertanyaan: "Bagaimana jika saya tidak dapat mengikuti OMB UMN 2024?",
      jawaban: "Berpartisipasi dalam kegiatan OMB UMN 2024 adalah salah satu syarat dalam <b>kelulusan</b> di Universitas Multimedia Nusantara. Oleh karena itu, apabila seorang Peserta OMB UMN 2024 <b>TIDAK MENGIKUTI</b> dan <b>TIDAK LULUS</b> di OMB UMN 2024, maka peserta tersebut harus <b>mengikuti dan lulus pada kegiatan OMB UMN pada tahun berikutnya.</b>",
      tipe: [0],
      nomor: 2,
    },
    {
      pertanyaan: "Apakah saya bisa izin tidak mengikuti OMB UMN 2024?",
      jawaban: "Segala perizinan untuk tidak mengikuti kegiatan OMB UMN 2024 hanya akan diberikan dalam <b>kasus penting </b> yang telah dipertimbangkan oleh divisi <b>Caksana atau Public Relations OMB UMN 2024.</b>",
      tipe: [0],
      nomor: 3,
    },
    {
      pertanyaan: "Apabila saya memiliki urusan sebelum jadwal OMB UMN 2024 dirilis, apakah saya diperbolehkan untuk meninggalkan kegiatan terlebih dahulu?",
      jawaban: "Pihak OMB UMN 2024 <b>tidak mengizinkan</b> peserta untuk keluar dari rangkaian terlebih dahulu karena rangkaian kegiatan OMB UMN 2024 merupakan kegiatan yang <b>wajib</b> diikuti dari awal hingga akhir. Namun, apabila izin <b>resmi dari pihak UMN</b> akan menjadi pertimbangan. Walaupun sudah menerima izin, mahasiswa tetap diwajibkan mengikuti OMB UMN 2024.",
      tipe: [0],
      nomor: 4,
    },
    {
      pertanyaan: "Bagaimana jika saya dinyatakan tidak lulus OMB UMN tahun ini?",
      jawaban: "Apabila peserta OMB UMN 2024 dinyatakan tidak lulus pada tahun ini, peserta <b>wajib mendaftar dan mengikuti OMB UMN pada tahun berikutnya</b> karena keikutsertaan dalam OMB UMN merupakan <b>syarat kelulusan</b> di UMN.",
      tipe: [0],
      nomor: 5,
    },
    {
      pertanyaan: "Pukul berapa rangkaian kegiatan OMB UMN 2024 akan selesai?",
      jawaban: "Seluruh peserta <b>wajib</b> mengikuti setiap rangkaian OMB UMN 2024 dari awal hingga akhir. Apabila orang tua ingin menjemput, dipersilakan untuk <b>menjemput di titik penjemputan masing-masing pada pukul 15.00 WIB.</b>",
      tipe: [0],
      nomor: 6,
    },
    {
      pertanyaan: "Jika saya mengikuti OMB, tapi tidak mengikuti Perkenalan Prodi, apakah akan tetap lulus OMB?",
      jawaban: "Kegiatan OMB dan Perkenalan Prodi merupakan kegiatan <b>wajib</b> yang diikuti oleh mahasiswa UMN. Oleh sebab itu, mahasiswa diharapkan bisa mengikuti keseluruhan rangkaian dengan baik.",
      tipe: [0],
      nomor: 7,
    },
    {
      pertanyaan: "Bagaimana jika saya memiliki kendala pada formulir keikutsertaan OMB UMN 2024, seperti tidak bisa mengakses tautan, mengunggah berkas, dll?",
      jawaban: "Seluruh peserta <b>wajib</b> mengikuti dan memperhatikan tahapan berikut ini: <br><ol><li>Pastikan koneksi internet dalam lancar.</li><li>Ukuran maksimal <i>file</i> dibawah 100 MB dan tidak lebih.</li><li><i>File</i> wajib berbentuk PDF.</li><li>Apabila masih berkendala, silakan coba untuk me-refresh halaman kembali dan mengisi ulang formulir.</li></ol> Jika sudah mengikuti dengan benar tetapi tetap tidak ada hasil, silakan menghubungi <b>LINE OA (@yqq6359s)</b> atau <b>WhatsApp (085179959923)</b> dengan screenshot sebagai bukti pendukung.",
      tipe: [0],
      nomor: 8,
    },
    {
      pertanyaan: "Apa saja syarat untuk mengikuti OMB UMN 2024?",
      jawaban: "Peserta <b>wajib</b> melakukan beberapa persyaratan berikut ini: <ol><li>Mengumpulkan dan mengisi formulir lembar persetujuan keikutsertaan yang terdapat di halaman utama website resmi OMB UMN 2024.</li><li>Mengisi formulir riwayat kesehatan yang terdapat pada surat edaran rektorat.</li><li>Menyelesaikan kewajiban registrasi ulang kepada pihak kampus.</li></ol> Apabila terdapat kendala atau pertanyaan terkait registrasi ulang, silakan langsung menghubungi <b>WhatsApp marketing UMN (081286224155).</b>",
      tipe: [0],
      nomor: 9,
    },
    {
      pertanyaan: "Apa saja tata cara mengikuti OMB UMN 2024 luring?",
      jawaban: "Peserta <b>wajib</b> untuk mencermati tata cara mengikuti OMB UMN 2024 luring: <ol><li>Wajib datang tepat waktu dan mengikuti rangkaian kegiatan OMB UMN 2024 dari awal hingga akhir.</li><li>Wajib mempersiapkan dan membawa barang-barang yang dibutuhkan dalam mengikuti rangkaian kegiatan OMB UMN 2024.</li></ol>",
      tipe: [0],
      nomor: 10,
    },
    // Peraturan
    {
      pertanyaan: "Bagaimana jika datang terlambat pada saat hari pelaksanaan OMB UMN 2024?",
      jawaban: "OMB UMN 2024 <b>tidak memiliki waktu dispensasi</b> mengenai keterlambatan. Apabila peserta datang terlambat, peserta tersebut akan <b>dipulangkan</b> dan harus <b>mengulang</b> kegiatan OMB UMN pada tahun berikutnya.",
      tipe: [1],
      nomor: 1,
    },
    {
      pertanyaan: "Apakah diperbolehkan membawa gawai saat mengikuti rangkaian kegiatan OMB UMN 2024?",
      jawaban: "Peserta hanya diperbolehkan membawa <b>satu gawai</b>. Gawai tersebut harus <b>dinonaktifkan</b> dan <b>dititipkan kepada Sakha (PIC)</b> masing-masing selama rangkaian kegiatan OMB UMN 2024 berlangsung.",
      tipe: [1],
      nomor: 2,
    },
    {
      pertanyaan: "Apakah diperbolehkan membawa kendaraan pribadi saat pelaksanaan OMB UMN 2024?",
      jawaban: "Seluruh Peserta OMB UMN 2024 <b>dilarang untuk membawa kendaraan pribadi</b> karena lahan parkir yang tersedia di area UMN akan digunakan untuk pelaksanaan kegiatan OMB UMN 2024. Seluruh peserta dianjurkan untuk <b>diantar, menggunakan kendaraan umum, atau menggunakan fasilitas <i>shuttle bus</i> </b> yang telah disediakan oleh pihak kampus. Panitia OMB UMN 2024 <b>tidak bertanggung jawab atas keamanan</b> dari kendaraan yang diparkirkan di luar area kampus.",
      tipe: [1],
      nomor: 3,
    },
    {
      pertanyaan: "Apakah Peserta OMB UMN 2024 disediakan makanan?",
      jawaban: "Peserta <b>diwajibkan membawa bekal makanan (4 sehat 5 sempurna) dan botol minum</b> masing-masing. Penggunaan alat makan, tempat makan, serta botol minum <b>wajib tidak sekali pakai</b>. Ukuran botol minum <b>minimal 600 ml</b>.",
      tipe: [1],
      nomor: 4,
    },
    {
      pertanyaan: "Apakah Peserta OMB UMN diperbolehkan untuk memakai riasan wajah?",
      jawaban: "Peserta OMB UMN 2024 <b>dilarang untuk menggunakan segala jenis riasan wajah</b> selama kegiatan OMB UMN 2024 berlangsung.",
      tipe: [1],
      nomor: 5,
    },
    {
      pertanyaan: "Apakah Peserta OMB UMN diperbolehkan untuk menggunakan aksesoris seperti kalung, anting, gelang, dan cincin?",
      jawaban: "Peserta OMB UMN <b>tidak diperbolehkan untuk menggunakan aksesoris</b> dalam bentuk apa pun selama kegiatan OMB UMN berlangsung. Penggunaan <b>aksesoris dengan kepercayaan tertentu diperbolehkan</b> dengan syarat <b>ditutupi plester dengan warna kulit</b>.",
      tipe: [1],
      nomor: 6,
    },
    {
      pertanyaan: "Apakah diperbolehkan membawa barang lain yang tidak dibutuhkan selama rangkaian kegiatan OMB UMN 2024?",
      jawaban: "Peserta OMB UMN 2024 <b>dilarang untuk membawa barang yang tidak berhubungan</b> dengan pelaksanaan OMB UMN 2024.",
      tipe: [1],
      nomor: 7,
    },
    // Atribut
    {
      pertanyaan: "Apakah diperbolehkan memakai kaus berkerah dengan ornamen/logo/garis pada bagian kerah dan lengan saat mengikuti rangkaian kegiatan OMB UMN 2024?",
      jawaban: "Kaus <b>diwajibkan berwarna polos tanpa motif atau corak, tidak berkantong,</b> dan <b>tidak bergaris</b>. Apabila terdapat logo/gambar, harus mengikuti ketentuan yang berlaku. Logo yang diperbolehkan <b>hanya di bagian depan</b> dan <b>ukuran maksimal 8X8 cm</b>.",
      tipe: [2],
      nomor: 1,
    },
    {
      pertanyaan: "Bagaimana peraturan pakaian bagi peserta OMB UMN 2024 yang menggunakan hijab?",
      jawaban: "Bagi peserta OMB UMN 2024 yang menggunakan hijab <b>tidak diperkenankan menggunakan kaus berkerah panjang</b>. Sebagai gantinya, peserta dapat <b>wajib menggunakan hijab dan manset yang berwarna hitam</b>.",
      tipe: [2],
      nomor: 2,
    },
    {
      pertanyaan: "Apakah peserta OMB UMN 2024 boleh menggunakan handuk selain handuk Good Morning?",
      jawaban: "Peserta OMB UMN 2024 <b>diwajibkan untuk menggunakan handuk Good Morning</b> yang telah ditentukan. Penggunaan handuk selain yang telah ditetapkan tidak diperbolehkan sebagai <b>syarat</b> dan <b>identitas</b> kegiatan.",
      tipe: [2],
      nomor: 3,
    },
    {
      pertanyaan: "Bagaimana jika peserta tidak memenuhi kelengkapan atribut atau hal lainnya terkait pelaksanaan OMB UMN 2024 yang diwajibkan?",
      jawaban: "Peserta diwajibkan untuk membawa seluruh atribut ataupun kelengkapan yang diwajibkan karena <b>sudah diumumkan jauh sebelum OMB berlangsung</b> sehingga tidak ada alasan untuk tidak memenuhi atribut yang diwajibkan.",
      tipe: [2],
      nomor: 4,
    },
    {
      pertanyaan: "Apakah bagi peserta OMB UMN 2024 yang memiliki rambut panjang diperbolehkan untuk digerai?",
      jawaban: "Peserta yang memiliki rambut panjang, baik perempuan dan laki-laki, <b>wajib</b> untuk mengikat rambut dengan <b>ikat rambut hitam polos dan tidak beraksesoris</b>. Bagi peserta yang berponi panjang, wajib untuk menggunakan <b>bando atau jepitan berwarna hitam</b>.",
      tipe: [2],
      nomor: 5,
    },
    {
      pertanyaan: "Bagaimana peraturan mengenai penggunaan celana jeans hitam bagi peserta OMB UMN 2024?",
      jawaban: "Peserta OMB UMN 2024 wajib mengenakan celana jeans panjang <b>berwarna hitam pekat polos</b> dengan model <b><i>slim fit</i> atau <i>regular fit</i>, tidak robek, dan tidak dilipat keluar</b>.",
      tipe: [2],
      nomor: 6,
    },
    {
      pertanyaan: "Bagaimana peraturan mengenai penggunaan sepatu bagi peserta OMB UMN 2024?",
      jawaban: "Peserta OMB UMN 2024 <b>wajib</b> menggunakan <b>sepatu kets yang nyaman dan bersifat mengencangkan</b> agar peserta dapat mengikuti rangkaian dengan <b>aman</b> dan <b>nyaman</b>.",
      tipe: [2],
      nomor: 7,
    },
    // Orang Tua
    {
      pertanyaan: "Apakah orang tua boleh menunggu di salah satu gedung di Universitas Multimedia Nusantara?",
      jawaban: "Orang tua peserta hanya diperbolehkan untuk mengantar Peserta OMB UMN 2024 sampai di depan <b>pintu gerbang  Universitas Multimedia Nusantara.</b>",
      tipe: [3],
      nomor: 1,
    },
    {
      pertanyaan: "Jika Perlengkapan atau atribut saya tertinggal, apakah boleh diantarkan atau dititipkan oleh orang tua/wali?​",
      jawaban: "Barang-barang seperti pakaian dan keperluan penugasan Peserta OMB UMN 2024, <b>tidak boleh dititipkan dengan alasan apapun.</b>  Barang yang dititipkan hanya boleh barang-barang <b>yang bersifat urgensi tinggi</b>, seperti <b>obat-obatan pribadi.</b>",
      tipe: [3],
      nomor: 2,
    },
    {
      pertanyaan: "Apakah orang tua dapat menghubungi Peserta OMB UMN 2024 selama rangkaian berlangsung?",
      jawaban: "Seluruh gawai Peserta OMB UMN 2024 <b>dinonaktifkan dan dititipkan kepada Sakha (PIC)</b> masing-masing selama rangkaian berlangsung. Gawai akan <b>dikembalikan setelah rangkaian</b> kegiatan telah selesai.",
      tipe: [3],
      nomor: 3,
    },
    {
      pertanyaan:"Bagaimana orang tua dapat menjemput atau menghubungi Peserta OMB UMN 2024 jika ada hal yang mendesak?",
      jawaban:"Jika ada hal <b>mendesak</b>, Orang tua Peserta OMB UMN 2024 dapat mendatangi <b>booth Caksana (Public Relations OMB UMN 2024)</b> yang terletak pada <b>lobby B UMN</b> untuk menghubungi Peserta OMB UMN 2024.",
      tipe:[3],
      nomor:4,
    },
    // Lain-lain
    {
      pertanyaan: "Apakah OMB UMN menyediakan obat-obatan?",
      jawaban: "OMB UMN 2024 menyediakan <b>obat-obatan general</b> untuk seluruh Peserta OMB UMN 2024. Namun, Peserta OMB UMN 2024 yang <b>memerlukan obat khusus wajib membawa obat-obatan pribadi.</b>",
      tipe: [4],
      nomor: 1,
    },
    {
      pertanyaan: "Apa yang harus saya lakukan jika terdaftar dalam dua kelompok di OMB UMN 2024?",
      jawaban: "Bagi Peserta OMB UMN 2024 yang terdaftar dalam dua kelompok, silakan menghubungi <b>LINE OA (@yqq6359s)</b> atau <b>WhatsApp (085179959923)</b> untuk konfirmasi lebih lanjut.",
      tipe: [4],
      nomor: 2,
    },
    {
      pertanyaan: "Apa yang harus saya lakukan jika saya memiliki pertanyaan atau saran untuk OMB UMN 2024?",
      jawaban: "Peserta OMB UMN 2024 dapat memberikan pertanyaan secara langsung kepada <b>Sakha atau Person In Charge</b> masing-masing. Peserta OMB UMN 2024 juga bisa menghubungi <b>akun resmi OMB UMN</b> untuk menyampaikan pertanyaan dan saran yang dimiliki. ",
      tipe: [4],
      nomor: 3,
    },
  ],
};

export default DaftarFaq;
